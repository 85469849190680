import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {TicketsListRuntimeProps, TicketsListOwnProps} from './interfaces'
import {TicketsList as Presentation} from './tickets-list'

const mapRuntime = ({
  state,
  actions: {selectPlace, selectTicket, unselectPlace, unselectTicket},
}: DetailsPageAppProps): TicketsListRuntimeProps => ({
  selectedPlaces: state.seating.selectedPlaces,
  showAccessibilityMode: state.seating.showAccessibilityMode,
  selectedTickets: state.selectedTickets,
  selectPlace,
  selectTicket,
  unselectPlace,
  unselectTicket,
})

export const TicketsList = connect<TicketsListOwnProps, TicketsListRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
