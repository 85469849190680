import {withTranslation} from '@wix/yoshi-flow-editor'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {SeatingPlanRuntimeProps} from './interfaces'
import {SeatingPlan as Presentation} from './seating-plan'

const mapRuntime = ({
  state: {
    seating: {plan, loading, selectedPlaces, showAccessibilityMode},
    tickets,
    selectedTickets,
    event,
  },
  actions: {selectTicket, unselectTicket, checkout, unselectPlace, selectPlaces, setShowAccessibilityMode},
}: DetailsPageAppProps): SeatingPlanRuntimeProps => ({
  selectedPlaces,
  plan,
  tickets,
  selectedTickets,
  selectTicket,
  unselectTicket,
  checkout,
  loading,
  event,
  selectPlaces,
  unselectPlace,
  showAccessibilityMode,
  setShowAccessibilityMode,
})

export const SeatingPlanModal = connect<{}, SeatingPlanRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
export * from './interfaces'
