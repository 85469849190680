import * as React from 'react'
import {DropdownOptionProps} from 'wix-ui-tpa/Dropdown'
import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import {Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import s from './filters.scss'
import {StyledFloatingDropdown} from './styled-dropdown'
import {VerticalDivider} from './vertical-divider'
import {FilterContainer} from './filter-container'
import {FiltersProps} from '.'

export const Filters = ({
  plan,
  tickets,
  selectPrice,
  selectLocation,
  selectedZone: selectedZoneId,
  selectedPrice: selectedPriceId,
  showAccessibilityMode,
}: FiltersProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  const defaultOption = {id: 'all', value: t('seatings_filters_all'), isSelectable: true}

  const prices: DropdownOptionProps[] = tickets.map(ticket => ({
    id: ticket.price.value,
    value: getFormattedMoney(ticket.price),
    isSelectable: true,
  }))

  const rows: DropdownOptionProps[] = plan.sectors
    .flatMap(sector => sector.elements)
    .filter(element => element.type === Type.ROW)
    .map(row => ({id: String(row.id), value: t('seatings_filters_row', {title: row.title}), isSelectable: true}))

  const selectedPrice = selectedPriceId ? prices.find(price => price.id === selectedPriceId) : defaultOption
  const selectedZone = selectedZoneId ? rows.find(row => row.id === selectedZoneId) : defaultOption

  React.useEffect(() => {
    selectPrice(null)
    selectLocation(null)
  }, [showAccessibilityMode, selectPrice, selectLocation])

  return (
    <div className={classNames(s.container, {[s.mobileContainer]: isMobile})}>
      <FilterContainer>
        <StyledFloatingDropdown
          dataHook={DH.SEATING_PRICE_FILTER}
          label={t('seatings_filters_priceLabel')}
          value={selectedPrice.id}
          ariaLabel={t('seatings_filters_priceAriaLabel', {value: selectedPrice.value})}
          options={[defaultOption, ...prices]}
          onChange={selectedOption => selectPrice(selectedOption.id === defaultOption.id ? null : selectedOption.id)}
        />
      </FilterContainer>
      {isMobile ? null : <VerticalDivider />}
      <FilterContainer>
        <StyledFloatingDropdown
          dataHook={DH.SEATING_ZONE_FILTER}
          value={selectedZone.id}
          label={t('seatings_filters_zoneLabel')}
          ariaLabel={t('seatings_filters_zoneAriaLabel', {value: selectedZone.value})}
          options={[
            defaultOption,
            {
              id: '0',
              value: t('seatings_filters_rows', {count: rows.length}),
              isSectionTitle: true,
              isSelectable: false,
            },
            ...rows,
          ]}
          onChange={selectedOption => selectLocation(selectedOption.id === defaultOption.id ? null : selectedOption.id)}
        />
      </FilterContainer>
    </div>
  )
}
