import * as React from 'react'
import {FloatingDropdown} from 'wix-ui-tpa/FloatingDropdown'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {classes} from './styled-dropdown.st.css'
import {StyledDropdownProps} from '.'

export const StyledFloatingDropdown = ({options, ariaLabel, label, value, dataHook, onChange}: StyledDropdownProps) => {
  const {isMobile} = useEnvironment()

  return (
    <FloatingDropdown
      data-hook={dataHook}
      label={label}
      value={value}
      aria-label={ariaLabel}
      className={classNames(classes.dropdown, {[classes.mobileDropdown]: isMobile})}
      options={options}
      onChange={onChange}
    />
  )
}
