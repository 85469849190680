import * as React from 'react'
import {TicketsListContainer} from '../../tickets-list-container'
import {Ticket} from '../../ticket'
import {getSelectedTicketQuantity} from '../../../../../selectors/selected-tickets'
import {SelectedPlace} from '../../../../../types/seating'
import {TicketsListProps} from '.'

export const TicketsList = ({
  selectPlace,
  selectTicket,
  selectedTickets,
  unselectPlace,
  unselectTicket,
  showAccessibilityMode,
  seats,
  selectedPlaces,
}: TicketsListProps) => {
  const handlePlaceClick = (clickedPlace: SelectedPlace, selected: boolean) => {
    const ticketId = clickedPlace.ticketId
    const placeId = clickedPlace.id

    if (selected) {
      unselectTicket({ticketId, count: getSelectedTicketQuantity(selectedTickets, ticketId) - 1, placeId})
      unselectPlace(placeId)
    } else {
      selectPlace(clickedPlace)
      selectTicket({
        ticketId,
        count: getSelectedTicketQuantity(selectedTickets, ticketId) + 1,
        placeId,
      })
    }
  }

  return (
    <TicketsListContainer>
      {seats.map((seat, index) => {
        const selected = selectedPlaces.some(selectedPlace => selectedPlace.id === seat.id)

        return (
          <Ticket
            showAccessibilityMode={showAccessibilityMode}
            key={seat.id}
            lastTicket={index === seats.length - 1}
            place={seat}
            onClick={clickedPlace => handlePlaceClick(clickedPlace, selected)}
            selected={selected}
          />
        )
      })}
    </TicketsListContainer>
  )
}
