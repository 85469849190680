import * as React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {classes} from './tickets-count-info.st.css'
import {TicketsCountInfoProps} from '.'

export const TicketsCountInfo = ({seatsCount}: TicketsCountInfoProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  return (
    <Text className={classNames(classes.seatsInfo, {[classes.mobile]: isMobile})}>
      {seatsCount === 1 ? t('seatings_ticket_total') : t('seatings_tickets_total', {count: seatsCount})}
    </Text>
  )
}
